<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入号码"
					style="width: 200px;margin-right: 10px;"></el-input>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.time"
					style="width: 200px;margin-right: 10px;"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">查询</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download"
					@click="getExport">导出</el-button>
			</div>
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="phone" label="号码" align="center"></el-table-column>
						<el-table-column prop="time" label="时间" align="center"></el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
							:page-size="query.pageSize" :total="pageTotal"
							@current-change="handlePageChange"></el-pagination>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { webClue, downloadWebClue } from '../api/index.js';
	export default {
		name: 'webClue',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					phone: '',
					pageIndex: 1,
					pageSize: 30,
					time: new Date(),
				},
				tableData: [],
				pageTotal: 0,

			};
		},

		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			getData() {
				let data = {
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					phone: this.query.phone,
					time: this.toDateTimeStr(this.query.time),
				};
				webClue(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getExport() {
				let data = {
					time: this.toDateTimeStr(this.query.time),
				};
				downloadWebClue(data).then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '网站线索' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
		}
	};
</script>
<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}
</style>