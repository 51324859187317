<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<div>
					<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
						clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId"
						@change="getfetchDefaultCompanies" filterable clearable placeholder="子代理"
						class="handle-select mr10" style="width: 260px;">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>

					<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
						@change="companyChange" filterable clearable v-show="isNormal" placeholder="企业"
						class="handle-select mr10">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
						v-show="isNormal" placeholder="部门" class="handle-select mr10" style="width: 180px">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-select size="small" v-model="query.normalId" filterable clearable v-show="isNormal"
						placeholder="员工" class="handle-select mr10" style="width: 180px">
						<el-option v-for="item in users" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<el-form label-width="120">
					<div class="form-items-wrapper">
						<el-form-item label="当前进度" v-if="customerScheduleList.length > 0">
							<el-select size="small" v-model="query.schedule" filterable clearable placeholder="请选择进度"
								class="mr10">
								<el-option v-for="item in customerScheduleList" :key="item.index" :label="item.str"
									:value="item.index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="客户来源" v-if="customerScheduleList.length > 0">
							<el-select size="small" v-model="query.source" filterable clearable placeholder="请选择客户来源"
								class="mr10">
								<el-option v-for="item in ustomerSourceList" :key="item" :label="item"
									:value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="跟进状态" v-if="customerScheduleList.length > 0">
							<el-select size="small" v-model="query.notUpdated" filterable clearable
								placeholder="请选择跟进状态" class=" mr10">
								<el-option label="超3天未跟" value="3"></el-option>
								<el-option label="超7天未跟" value="7"></el-option>
								<el-option label="超10天未跟" value="10"></el-option>
								<el-option label="超15天未跟" value="15"></el-option>
								<el-option label="超30天未跟" value="30"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别" v-if="customerScheduleList.length > 0">
							<el-select size="small" v-model="query.gender" filterable clearable placeholder="请选择性别"
								class=" mr10">
								<el-option label="男性" value="1"></el-option>
								<el-option label="女性" value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="查询内容" v-if="customerScheduleList.length > 0">
							<el-select size="small" v-model="query.type" filterable clearable placeholder="请选择进度"
								class=" mr10">
								<el-option label="手机号码" value="1"></el-option>
								<el-option label="客户姓名" value="2"></el-option>
								<el-option label="客户概况" value="3"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="输入内容" v-if="customerScheduleList.length > 0">
							<el-input size="small" v-model="query.conditions" clearable placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item label="创建时间" v-if="customerScheduleList.length > 0">
							<el-date-picker v-model="timeArr" type="daterange" unlink-panels clearable
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime()"
								size="small" style="width: 240px;"></el-date-picker>
						</el-form-item>
						<div v-for="(item, index) in customList" :key="index">
							<el-form-item :label="item.label" v-if="item.type == 'multiline-text'">
								<el-input size="small" v-model="item.textVal" type="text"
									placeholder="请输入内容"></el-input>
							</el-form-item>
							<el-form-item :label="item.label" v-if="item.type == 'date'">
								<el-date-picker size="small" v-model="item.textVal" value-format="yyyy-MM-dd"
									placeholder="选择日期" clearable></el-date-picker>
							</el-form-item>
							<el-form-item :label="item.label" v-if="item.type == 'time'">
								<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间"
									clearable></el-time-select>
							</el-form-item>
							<el-form-item :label="item.label" v-if="item.type == 'radio-drop-down-box'">
								<el-select v-model="item.multiSelectDropDownBox" size="small" clearable>
									<el-option :label="items" v-for="items in item.options" :key="items"
										:value="items"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="item.label" v-if="item.type == 'multi-select-drop-down-box'">
								<el-select v-model="item.multiSelectDropDownBox" size="small" multiple>
									<el-option :label="items" v-for="items in item.options" :key="items.index"
										:value="items"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="item.label" v-if="item.type == 'region'">
								<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress"
									clearable placeholder="请选择省市区" filterable />
								<el-input size="small" v-model="detail" placeholder="请输入地址"
									@change="handleChangedetail"></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>

			</div>

			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin:0 10px 20px 0;"
				@click="handleSearch">查询</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-circle-plus-outline"
				style="margin:0 10px 20px 0;" @click="addData">手动添加</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2" style="margin:0 10px 20px 0;"
				@click="handleDownload">导出</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" style="margin:0 10px 20px 0;"
				@click="handleDelete">批量释放</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-s-promotion"
				style="margin:0 10px 20px 0;" @click="turnaroundCustomer" v-show="isNormal">
				转移
			</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download" class="handle-del ml10"
				style="margin:0 10px 20px 0;" @click="getdownloadIntentionTemplate">
				下载模板
			</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" style="margin:0 10px 20px 0;"
				@click="getRemoveCrm2" v-show="isNormal">批量删除</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" @click="uploadExcel"
				style="margin:0 10px 20px 0;">导入</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-s-fold" style="margin:0 0 20px 0;"
				@click="getColumnSetting">
				自定义列
			</el-button>
			<div class="data-pool-operation">
				<p>
					已选
					<span>{{ multipleSelectionlen||0}}</span>
					条线索
				</p>
			</div>
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
						header-cell-class-name="table-header" @row-click="showData"
						:cell-style="{ cursor: 'pointer', padding: '6px' }" :row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
						@selection-change="handleSelectionChange" height="500">
						<el-table-column type="selection" width="55" align="center"
							v-if="customData.length>0"></el-table-column>
						<el-table-column v-for="item in customData" :key="item.value" :prop="item.value"
							:label="item.label" align="center" :width="item.width">
							<template #default="scope" v-if="item.value=='schedule'">
								<div v-if="scope.row.scheduleIndex == '1'"
									style="color: #fff;background-color: #f56c6c;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '2'"
									style="color: #fff;background-color: #00a0ea;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '3'"
									style="color: #fff;background-color: #F39801;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '4'"
									style="color: #fff;background-color: #eb6100;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '5'"
									style="color: #fff;background-color: #8fc320;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '6'"
									style="color: #fff;background-color: #4CB8A6;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '7'"
									style="color: #fff;background-color: #63C9EE;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '8'"
									style="color: #fff;background-color: #68F25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '9'"
									style="color: #fff;background-color: #BBF25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '10'"
									style="color: #fff;background-color: #9D5CF2;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else
									style="color: #fff;background-color: #000;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
							</template>
							<template #default="scope" v-if="item.value=='profile'">
								<div v-if="scope.row.journal">
									<div
										style="background-color: rgba(0,255,0,.2);padding: 2px 0;border-radius: 4px;margin-bottom: 6px;">
										{{ scope.row.journal[scope.row.journal.length - 1].value }}
									</div>
									<div style="background-color: rgba(0,255,0,.2);padding: 2px 0;border-radius: 4px;">
										{{ scope.row.journal[scope.row.journal.length - 1].label.slice(0, 10) }}
									</div>
								</div>
							</template>

						</el-table-column>

						<el-table-column label="操作" width="190" align="center" fixed="right" v-if="customData.length>0">
							<template #default="scope">
								<div style="display: inline-block;margin-right: 16px;">
									<el-button type="primary" :class="[theme]" size="mini" v-if="deviceName !== null"
										@click.enter.stop="handleSocketSend(scope.row)" :disabled="bodadisabled">
										拨打
									</el-button>
								</div>
								<el-button size="small" type="text" icon="el-icon-delete" class="red"
									@click.enter.stop="handleDelete2(scope.row.id)">释放客户</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>

			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30,50,100, 300, 500, 1000]"
					:page-size="query.pageSize" :total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="客户详情" v-model="drawer" direction="rtl" size="75%" modal="false" :with-header="false">
				<div class="details-box">
					<div class="header">
						<div class="user-box">
							<div class="user-name">
								<div v-if="customerDetails.gender == '男' || customerDetails.gender == ''"><img
										src="../assets/img/man.png" alt="" /></div>
								<div v-if="customerDetails.gender == '女'"><img src="../assets/img/girl.png" alt="" />
								</div>
								<div class="name">
									<div>客户姓名</div>
									<el-input size="small" v-model="customerDetails.customerName" type="text"
										style="width: 200px;" :disabled="drawerInputDis"
										:value="customerDetails.customerName || '无'"
										:class="[{ bordis: drawerInputDis == true }]"></el-input>
								</div>
							</div>
							<div class="user-btn">
								<el-button size="small" type="primary" :class="[theme]" style="margin-left: 10px;"
									@click="handleEdit">编辑</el-button>
								<i class="el-icon-close"
									style="font-size: 26px;position: relative;top: 1px;margin-left: 10px;color: #8e8e8e;"
									@click="drawer = false"></i>
							</div>
						</div>
						<div class="user-info">
							<div class="list">
								<div class="name-label">电话号码</div>
								<div class="name-data">{{ customerDetails.phoneNumber }}</div>
							</div>
							<div class="list">
								<div class="name-label">来源</div>
								<div class="name-data">
									<i
										style="width: 6px;height: 6px;background-color: #3f8AFD;border-radius: 50%;display: inline-block;"></i>
									{{ customerDetails.source || '无' }}
								</div>
							</div>
							<div class="list">
								<div class="name-label">当前跟进人</div>
								<div class="name-data">{{ customerDetails.normalName }}</div>
							</div>
							<div class="list">
								<div class="name-label">添加时间</div>
								<div class="name-data">{{ customerDetails.createdAt }}</div>
							</div>
						</div>
					</div>
					<div class="nav-tab">
						<div class="ul-box">
							<div v-for="item in customerScheduleList" :key="item.index" @click="tapNav(item.index)"
								:class="customerDetails.scheduleIndex >= item.index ? 'active' : ''">
								{{ item.str }}
							</div>
						</div>
					</div>
					<div class="drawer-content">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="联系人信息" name="contactInformation">
								<div class="title">
									<span></span>
									基本信息
								</div>
								<div class="call-record-box">
									<div class="call-list">
										<div class="item-info">
											<span class="lat">客户姓名:</span>
											<el-input size="small" v-model="form.customerName" type="text"
												style="width: 200px;" :disabled="drawerInputDis"
												:value="customerDetails.customerName"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info">
											<span class="lat">电话号码:</span>
											<el-input size="small" v-model="form.phoneNumber" type="text"
												style="width: 200px;" disabled :value="customerDetails.phoneNumber"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info">
											<span class="lat">客户性别:</span>
											<el-select size="small" v-model="form.gender" filterable clearable
												placeholder="请选择性别" style="width: 200px;" :disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]">
												<el-option key="1" label="男" value="男"></el-option>
												<el-option key="2" label="女" value="女"></el-option>
											</el-select>
										</div>

										<div class="item-info">
											<span class="lat">添加时间:</span>
											<el-input size="small" style="width: 200px;" v-model="form.createdAt"
												type="text" disabled :value="customerDetails.createdAt"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>

										<div class="item-info">
											<span class="lat">最后跟进时间:</span>

											<el-input size="small" v-model="form.updatedAt" type="text" disabled
												style="width: 200px;" :value="customerDetails.updatedAt"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">部门:</span>
											<el-select size="small" v-model="query.departmentId"
												@change="departmentChange" filterable clearable v-show="isNormal"
												placeholder="部门" class="handle-select mr10" style="width: 180px"
												:disabled="drawerInputDis">
												<el-option v-for="item in departments" :key="item.value"
													:label="item.label" :value="item.value"></el-option>
											</el-select>
										</div>

										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">跟进人:</span>
											<el-select size="small" v-model="customerDetails.normalName" filterable
												clearable placeholder="请选择跟进员工" v-if="isCompany || isAgent"
												:disabled="drawerInputDis">
												<el-option v-for="item in users" :key="item.value" :label="item.label"
													:value="item.value"></el-option>
											</el-select>
											<span v-else style="font-size: 12px;color: #ff0000;">需要选择部门才能更换跟进人</span>
										</div>
										<div class="item-info" v-for="item in customerDetails.customizeVo" :key="item">
											<span class="lat">{{ item.label }}:</span>
											<el-input size="small" v-if="item.type == 'multiline-text'"
												v-model="item.textVal" type="text" placeholder="请输入内容"
												style="width: 200px;" :disabled="drawerInputDis"></el-input>
											<el-date-picker class="placeholder-style" v-if="item.type == 'date'"
												v-model="item.textVal" value-format="yyyy-MM-dd" placeholder="选择日期"
												style="width: 200px;" size="small"
												:disabled="drawerInputDis"></el-date-picker>
											<el-time-select class="placeholder-style" v-if="item.type == 'time'"
												type="date" v-model="item.textVal" placeholder="选择时间"
												style="width: 200px;" size="small"
												:disabled="drawerInputDis"></el-time-select>
											<el-radio-group v-model="item.textVal"
												v-if="item.type == 'radio-drop-down-box'" @change="radioChange">
												<el-radio v-for="items in item.options" :label="items.str"
													:key="items.index" v-model="item.textVal"
													:disabled="drawerInputDis">
													{{ items.str }}
												</el-radio>
											</el-radio-group>
											<div v-if="item.type == 'multi-select-drop-down-box'">
												<el-checkbox-group v-model="item.multiSelectDropDownBox">
													<el-checkbox v-for="items in item.options" :key="items.index"
														:label="items.str" v-model="items.checked"
														:disabled="drawerInputDis">
														{{ items.str }}
													</el-checkbox>
												</el-checkbox-group>
											</div>
											<div v-if="item.type == 'region'"
												style="display: flex;flex-direction: row;">
												<el-cascader v-model="addressSelections" :options="addressData"
													@change="handleAddress" style="width: 230px" placeholder="请选择省市区"
													filterable :disabled="drawerInputDis" />
												<el-input size="small" v-model="detail" placeholder="请输入地址"
													@change="handleChangedetail" :disabled="drawerInputDis"
													style="width: 300px;margin-left: 10px;"></el-input>
											</div>
										</div>
										<div class="item-info">
											<span class="lat">客户概况：</span>
											<el-input size="small" style="width: 200px;" v-model="form.remark"
												type="textarea" :disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]"
												:value="customerDetails.remark"></el-input>
										</div>
									</div>
								</div>
								<div class="item-info">
									<span class="lat">通话记录:</span>
									<el-table :data="followUpRecordData" border class="table" ref="multipleTable"
										v-loading="loading" header-cell-class-name="table-header"
										:header-cell-style="{ 'text-align': 'center' }" :key="Math.random()"
										style="margin-top: 20px;">
										<el-table-column prop="customerName" label="客户姓名"
											align="center"></el-table-column>
										<el-table-column prop="phoneNumber" label="客户手机号"
											align="center"></el-table-column>
										<el-table-column prop="userName" label="员工姓名" align="center"></el-table-column>
										<el-table-column prop="userPhone" label="员工号码" align="center"></el-table-column>
										<el-table-column prop="callDuration" label="通话时长(秒)"
											align="center"></el-table-column>
										<el-table-column label="通话录音" prop="recordPath" width="350" align="center">
											<template #default="scope">
												<audio-player ref="audio" class="audio-box"
													:fileurl="scope.row.recordPath" :row="scope.row"></audio-player>
											</template>
										</el-table-column>
										<el-table-column prop="createdAt" label="拨打时间" align="center"
											width="190"></el-table-column>
									</el-table>
								</div>

								<div v-if="drawerInputDis == false"
									style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
									<el-button size="small" @click="getNoupdateIntentionCustomer">取消</el-button>
									<el-button size="small" type="primary" :class="[theme]"
										@click="updateIntentionCustomer()">确定</el-button>
								</div>
							</el-tab-pane>
							<el-tab-pane label="通话记录" name="callLog">
								<el-row>
									<el-col :span="24">
										<el-table :data="followUpRecordData" border class="table" ref="multipleTable"
											v-loading="loading" header-cell-class-name="table-header"
											:header-cell-style="{ 'text-align': 'center' }" :key="Math.random()">
											<el-table-column prop="customerName" label="客户姓名"
												align="center"></el-table-column>
											<el-table-column prop="phoneNumber" label="客户手机号"
												align="center"></el-table-column>
											<el-table-column prop="userName" label="员工姓名"
												align="center"></el-table-column>
											<el-table-column prop="userPhone" label="员工号码"
												align="center"></el-table-column>
											<el-table-column prop="callDuration" label="通话时长(秒)"
												align="center"></el-table-column>
											<el-table-column label="通话录音" prop="recordPath" width="350" align="center">
												<template #default="scope">

													<audio-player ref="audio" class="audio-box"
														:fileurl="scope.row.recordPath" :row="scope.row"></audio-player>
												</template>
											</el-table-column>
											<el-table-column prop="createdAt" label="拨打时间" align="center"
												width="190"></el-table-column>
										</el-table>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane :label="callRecordName+'（'+journallenght+'）'" name="callRecord">
								<el-button :class="[theme]" size="small" type="primary" style="margin: 10px 0;"
									@click="showcallRecordBox">添加批注</el-button>
								<el-table :data="customerDetails.journal" border class="table" ref="multipleTable"
									v-loading="loading" header-cell-class-name="table-header"
									:header-cell-style="{ 'text-align': 'center' }">
									<el-table-column prop="" label="跟进人" align="center">
										<template #default="scope">
											<div>{{ customerDetails.normalName }}</div>
										</template>
									</el-table-column>
									<el-table-column prop="value" label="批注内容" align="center"></el-table-column>
									<el-table-column prop="label" label="批注时间" align="center"></el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane :label="problemRecordName+'（'+operationLoglenght+'）'" name="problemRecord">
								<el-timeline-item v-for="(item, index) in customerDetails.operationLog" :key="index"
									:timestamp="item.label">{{ item.value }}</el-timeline-item>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</el-drawer>
		</div>
		<el-dialog title="批注记录" v-model="callRecordBox" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="内容" prop="customerName">
					<el-input size="small" style="padding: 2px 6px;" v-model="journal" type="textarea"
						placeholder="请输入拜访内容" rows="5"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="hidecallRecordBox">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="GetIntentionCustomeraddJournals">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="新建我的客户" v-model="addVisible" width="750px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="客户姓名" prop="customerName"><el-input size="small"
						v-model="form.customerName"></el-input></el-form-item>
				<el-form-item label="电话号码" prop="phoneNumber"><el-input size="small"
						v-model="form.phoneNumber"></el-input></el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select size="small" v-model="form.gender" filterable clearable placeholder="请选择性别"
						style="width: 100%;" :disabled="roleDis">
						<el-option key="1" label="男" value="男"></el-option>
						<el-option key="2" label="女" value="女"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数据来源" prop="source">
					<el-radio :label="item" v-for="(item, index) in ustomerSourceList" :key="index"
						v-model="form.source" @click.enter.prevent="getSource2(item)">
						{{ item }}
					</el-radio>
				</el-form-item>

				<div v-for="(item, index) in customList" :key="index">
					<el-form-item :label="item.label">
						<div v-if="item.type == 'multiline-text'"><el-input size="small" v-model="item.textVal"
								type="text" placeholder="请输入内容"></el-input></div>
						<div v-if="item.type == 'date'"><el-date-picker size="small" v-model="item.textVal"
								value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 100%"></el-date-picker></div>
						<div v-if="item.type == 'time'">
							<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间"
								style="width: 100%"></el-time-select>
						</div>
						<div v-if="item.type == 'radio-drop-down-box'">
							<el-radio :label="items" v-for="items in item.options" :key="items.index"
								v-model="item.textVal"></el-radio>
						</div>
						<div v-if="item.type == 'multi-select-drop-down-box'">
							<el-checkbox-group v-model="item.multiSelectDropDownBox">
								<el-checkbox :label="items" v-for="items in item.options"
									:key="items.index"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-if="item.type == 'region'">
							<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress"
								placeholder="请选择省市区" filterable />
							<el-input size="small" v-model="detail" placeholder="请输入地址"
								@change="handleChangedetail"></el-input>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="客户概况">
					<el-input size="small" type="textarea" v-model="form.remark"></el-input>
				</el-form-item>
				<el-form-item label="部门" v-show="isNormal">
					<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
						placeholder="部门" style="width: 100%">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="跟进人" prop="normalName" v-show="isNormal">
					<el-select size="small" v-model="form.normalName" filterable clearable placeholder="员工"
						class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button size="small" style="margin-left:75%;" @click="addVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="getAddIntentionCustomer('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="释放原因" v-model="shifang" width="500px" @close="closeshifangDialog"
			:close-on-click-modal="false">
			<el-form :model="shifangbox" :rules="rules" ref="form" label-width="100px" class="import-visible">
				<el-form-item label="原因" prop="shifangVal">
					<el-radio-group v-model="shifangbox.shifangVal" @change="shifangRadioChange"
						style="border: 1px solid #EBEEF5;padding: 8px;">
						<el-radio style="width: 100%;margin-bottom: 10px;" v-for="(item, index) in shifangList"
							:key="index" :label="item.str">{{ item.str }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: flex-end;">
				<el-button size="small" @click="closeshifangDialog" style="width: 100px;height: 40px;">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="deleteIntentionCustomers"
					style="width: 100px;height: 40px;">确定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="转移数据" v-model="transferVisible" width="450px" :close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="跟进员工" prop="userId">
					<el-select size="small" v-model="form.userId" placeholder="请选择跟进员工" :disabled="userDis"
						style="width:100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
					<el-button size="small" @click="transferVisible = false">取消</el-button>
					<el-button type="primary" :class="[theme]" size="small"
						@click="transferPhoneNumberPool">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="自定义列" v-model="customVisible" width="600px" @close="closCustomVisibleDialog">
			<div id="aaa-box">
				<el-table :data="customTableData" border class="table" ref="multipleTable"
					header-cell-class-name="table-header" :cell-style="{ padding: '6px' }"
					:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName" row-key="id"
					:class="{ 'not-sort': inputs }">
					<el-table-column prop="index" align="center" width="50" label="顺序"></el-table-column>
					<el-table-column prop="label" label="表头名称" align="center"></el-table-column>
					<el-table-column prop="enable" label="启用" align="center">
						<template #default="scope">
							<el-checkbox label="启用" v-model="scope.row.enable"
								@change="getcustomerEnable(scope.row)"></el-checkbox>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<div class="user-box">
			<el-dialog title="导入文件" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload ref="upload" action="/api/intentionCustomer/uploadIntention" :headers="token" :limit="1"
					:on-preview="handlePreview" :on-remove="handleRemove" :on-success="uploadSuccess"
					:auto-upload="false" :data="uploadform" :before-upload="beforeAvatarUpload">
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能上传excel文件</div>
				</el-upload>
				<el-checkbox-group v-model="uploadform.userIds" @change="handleCheckedCitiesChange" size="small">
					<el-checkbox v-for="(item, index) in users" :label="item.value" :key="item.value" border
						style="width: 180px;margin:10px 10px 10px 0;display: flex;flex-direction: row;">
						{{ item.label }}
					</el-checkbox>
				</el-checkbox-group>
				<el-button size="small" type="primary" :class="[theme]" style="margin-left: 80%;margin-top: 20px;"
					@click="submitUpload">确定</el-button>
			</el-dialog>

			<el-dialog v-model="filterBox" width="250px" title="重复数据">
				<div class="filter-box" style="width: 200px;height: 400px;overflow: scroll;">
					<div v-for="item in filterListData" :key="item">{{ item }}</div>
				</div>
			</el-dialog>
		</div>

		<el-dialog v-model="dialogIntention" :show-close="false" :close-on-click-modal="false">
			<div class="dialog-title">
				<i class="el-icon-s-custom"></i>
				<div class="title" v-if="callStatus == false">
					正在与
					<img src="../assets/img/phone.png" alt="" />
					&nbsp;{{ phoneNumber }}&nbsp;通话中...
				</div>
				<div class="title" v-else>
					与&nbsp;
					<img src="../assets/img/phone.png" alt="" />
					{{ phoneNumber }}&nbsp;通话结束
				</div>
				<div class="time">通话时长&nbsp;{{ formatSeconds(callDuration) }}</div>
			</div>
			<div style="min-height: 300px;overflow: hidden;">
				<div class="info-right">
					<el-form :model="formIntention" ref="form" label-width="140px">
						<el-form-item label="意向选择" prop="intention">
							<el-radio-group v-model="formIntention.intention">
								<el-radio v-for="item in optionsIntentionList" :key="item.value" :label="item.value"
									:value="item.value">{{ item.label }}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="备注">
							<el-input size="small" type="textarea" v-model="formIntention.remark"
								placeholder="请填写该客户通话备注" :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
						</el-form-item>

						<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
							<el-button type="primary" :class="[theme]" size="small" @click="getblack">加入黑名单</el-button>
							<el-button type="primary" :class="[theme]" size="small"
								@click="socketForced">强制结束</el-button>
							<el-button type="primary" :class="[theme]" size="small" :disabled="bodadisabled"
								@click="getSocketIntention">确定</el-button>

						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>

		<el-dialog v-model="callPopup5" title="黑名单号码" width="450px" :close-on-click-modal="false">
			<div class="call-type5">
				<div class="black-radio">
					<div class="title">原因</div>
					<el-radio-group v-model="blackremark" @change="blackRadioChange">
						<el-radio label="明确表示不再允许打电话">明确表示不再允许打电话</el-radio>
						<el-radio label="有明显投诉倾向">有明显投诉倾向</el-radio>
						<el-radio label="已确认非目标客户">已确认非目标客户</el-radio>
						<el-radio label="其他">其他</el-radio>
					</el-radio-group>
				</div>
				<div class="btn-box">
					<button @click="callPopup5Close">取消</button>
					<button @click="getblackListAdd">添加</button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		intentionCustomerList,
		download,
		intentionCustomergetDetails,
		addIntentionCustomer,
		updateIntentionCustomer,
		updateSchedule,
		getfindCallRecord,
		deleteIntentionCustomer,
		GetIntentionCustomeraddJournal,
		downloadIntentionCustomerList,
		turnaroundCustomer,
		removeCrm,
	} from '../api/recordIndex';
	import {
		fetchDepartment,
		fetchUsersByDepartmentId,
		fetchDefaultUsers
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent,
		getColumn,
		downloadIntentionTemplate
	} from '../api/agentIndex';
	import {
		getwebListCustomize,
		getCustomerSchedule,
		getCustomerSource,
		getCustomerRelease,
		getColumnSetting,
		updateColumnSetting
	} from '../api/tag.js';
	import {
		provinceAndCityData,
		regionData,
		provinceAndCityDataPlus,
		regionDataPlus,
		CodeToText,
		TextToCode
	} from 'element-china-area-data';
	import AudioPlayer from '../components/audioPlayer.vue';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import {
		fetchDataById,
		socketSend,
		getCallStatuss,
		socketIntention,
		blackListAdd,
		socketforced
	} from '../api/customerIndex';
	import Sortable from 'sortablejs';
	import draggable from 'vuedraggable';
	export default {
		components: {
			AudioPlayer,
		},
		data() {
			return {
				customData: [],

				customVisible: false,
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					userId: '',
					schedule: '',
					type: '',
					conditions: '',
					normalId: '',
					gender: '',
					startTime: '',
					endTime: '',
					pageIndex: 1,
					pageSize: 30,
					source: '',
					subAgentId: ''
				},
				ressderRegionList: [],
				showForm: true,
				fileList: [],
				tableData: [],
				agents: [],
				companys: [],
				departments: [],
				users: [],
				multipleSelection: [],
				multipleSelectionlen: 0,
				delList: [],
				followUpRecordData: [], //通话记录
				returnVisitData: [], //拜访记录
				input3: '',
				combinationSelect: '',

				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				transferVisible: false,
				isNormal: true,
				isNotAdmin: true,
				isAdmin: false,
				drawer: false,
				isAgent: false,
				isSubAgent: false,
				isCompany: false,
				pageTotal: 0,
				callDuration: 0,
				optionsIntentionList: [{
						label: '意向强',
						value: 'A'
					},
					{
						label: '意向一般',
						value: 'B'
					},
					{
						label: '无意向',
						value: 'C'
					},
					{
						label: '需再跟进',
						value: 'D'
					},
					{
						label: '无需跟进',
						value: 'E'
					}
				],
				form: {
					normalName: '',
					phoneNumber: '',
					gender: '',
					customerCompany: '',
					mailbox: '',
					customerIdentity: '',
					remark: '',
					customerName: '',
					source: '',
					userId: localStorage.getItem('user'),
					multilineText: '',
					customDate: '',
					customTime: '',
					radioDropDownBox: '',
					multiSelectDropDownBox: [],
					customize: []
				},
				uploadform: {
					userId: localStorage.getItem('user'),
					type: 1,
					userIds: []
				},
				upload: false,
				rules: {
					phoneNumber: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'change'
					}],
					normalName: [{
						required: true,
						message: '请选择跟进员工',
						trigger: 'change'
					}]
				},
				form2: {
					customerName: '',
					phoneNumber: '',
					intention: '',
					remark: ''
				},

				loading: false,
				activeName: 'contactInformation',
				customerDetails: '',
				idArr: '',
				rowIndex: '',
				lastId: '',
				nextId: '',
				prevDis: false,
				nextDis: false,
				drawerInputDis: true, //抽屉里面的input是否可以编辑
				tapnav: 1, //抽屉里的初防
				callRecordBox: false,
				scheduleKey: false,
				journal: '',
				timeArr: [],
				customList: [],
				customerScheduleList: [], //进度列表
				ustomerSourceList: [], //来源列表
				customize: {},
				shifang: false,
				shifangList: [],
				shifangbox: {
					shifangVal: ''
				},
				shifangId: '',
				// 选中数组
				ids: [],
				//地址信息
				addressData: regionData,
				addressSelections: [],
				province: '',
				city: '',
				town: '',
				detail: '',
				addressStr: '',
				subAgentList: [],
				subAgent: false,
				bodadisabled: false, //列表里面是否可以拨打
				customTableData: [],
				labelArr: [],
				desensitization: false,
				problemRecordName: '操作日志',
				operationLoglenght: 0,
				callRecordName: "批注记录",
				journallenght: 0,
				deviceName: null, //是否显示拨打按钮
				formIntention: {
					name: '',
					intention: 'C',
					remark: '',
					isRemark: 'false',
					attribute1: {},
					attribute2: {},
					attribute3: {},
					attribute4: {},
					attribute5: {}
				},

				curUserNumber: '',
				filterBox: false,


				formIntention: {
					name: '',
					intention: 'C',
					remark: '',
					isRemark: 'false',
					attribute1: {},
					attribute2: {},
					attribute3: {},
					attribute4: {},
					attribute5: {}
				},

				dialogIntention: false,
				blackremark: '其他',
				callPopup5: false,
				protect: false,
			};
		},
		computed: {
			collapse() {
				return this.$store.state.collapse;
			}
		},
		created() {
			this.subAgent = localStorage.getItem('subAgent');
			this.role = localStorage.getItem('ms_role');
			if (this.role === 'company') {
				this.isCompany = true;
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}
			if (this.role === 'normal') {
				this.isNormal = false;
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}
			if (this.role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}

			if (this.role === 'subAgent') {
				this.isAgent = true;
			}

			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isNotAdmin = false;
				this.isAgent = true;
			}
			if (this.role === 'department') {
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}
			this.init();
			this.getColumn();
			setInterval(() => {
				this.deviceName = localStorage.getItem('deviceName');
			}, 1000);
		},
		methods: {
			// 原始方法修改后  
			handleSocketSend(row) {
				console.log(row, 'ssljljl')
				let username = localStorage.getItem('ms_username');
				this.formIntention.name = row.customerName;
				this.formIntention.remark = row.remark;
				this.curUserNumber = row.phoneNumbers;
				let data = {
					name: username,
					info: row.phoneNumbers,
					type: 3,
					crm: true,
					filtration: this.getLocalStorageItem('filtration'),
				};
				this.bodadisabled = true;
				socketSend(data).then(res => {
					this.bodadisabled = false;
					let a = '与';
					let b = '通话中...';
					this.phoneNumber = row.phoneNumbers;
					if (res.data == 2) {
						this.dialogIntention = true;
						// this.formIntentionPhoneNumber = a + row.phoneNumber + b;
						setTimeout(() => {
							this.getCallStatus();
						}, 1000);
					} else if (res.data == 1) {
						this.$message.error('请APP重新进入电脑快拨页面');
						localStorage.removeItem('deviceName');
						this.manualDialing = false;
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else if (res.data == 0) {
						let text = '此号码为“风险号码”，禁止拨打！';
						let msg = this.phoneNumber + text;
						this.$message.error(msg);
						// this.getData();
					} else if (res.data == 3) {
						let text = '号码已被拉入黑名单,不可拨打';
						let msg = this.phoneNumber + text;
						this.$message.error(msg);
						//this.getData();
					}
				});
			},

			getCallStatus() {
				getCallStatuss(this.getLocalStorageItem('ms_username')).then(res => {
					if (res.code == 200) {
						console.log(res, '我是获取当前状态')
						this.callStatus = res.data.status; //this.callStatus==true  执行拨打下一次电话
						if (res.data.status == true) {
							this.imgbox = false;
							this.callDuration = res.data.callDuration; //通话时长
						} else {
							this.imgbox = true;
							setTimeout(() => {
								this.getCallStatus();
								this.callDuration++;
							}, 1000);
						}
					} else {
						this.$message.error('请求错误…………');
					}
				});
			},

			getout() {
				let data = {
					name: this.getLocalStorageItem('ms_username'),
					info: this.formIntention.intention,
					type: 4,
					remark: this.formIntention.remark,
					isRemark: this.formIntention.isRemark,
					phoneNumber: this.phoneNumber,
					customerName: this.formIntention.name,
				};
				socketIntention(data).then(res => {
					if (res.code == 200) {
						if (res.data == true) {
							this.getData();
							this.$message.success(res.message);
							this.dialogIntention = false;
							this.callDuration = 0;
							this.callStatus = false;
							this.setSeconds = this.getLocalStorageItem('setSeconds'); //重新获取间隔拨打时间
							this.formIntention.intention = 'C';
							this.formIntention.remark = '';
							this.phoneNumber = '';
							this.arrI = 0;
							this.formIntention.isRemark = 'false';
							this.formIntention.attribute1.label = '';
							this.formIntention.attribute1.value = '';
							this.formIntention.attribute2.label = '';
							this.formIntention.attribute2.value = '';
							this.formIntention.attribute3.label = '';
							this.formIntention.attribute3.value = '';
							this.formIntention.attribute4.label = '';
							this.formIntention.attribute4.value = '';
							this.formIntention.attribute5.label = '';
							this.formIntention.attribute5.value = '';
						} else if (res.data == false) {
							this.$message.error('请挂断电话后操作');
							this.getData();
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getSocketIntention() {
				this.bodadisabled = true;
				let data = {
					name: this.getLocalStorageItem('ms_username'),
					info: this.formIntention.intention,
					type: 4,
					remark: this.formIntention.remark,
					phoneNumber: this.phoneNumber,
					customerName: this.formIntention.name,
				};
				socketIntention(data).then(res => {
					this.bodadisabled = false;
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						if (res.data == true) {
							this.$message.success(res.message);
							this.dialogIntention = false;
							this.callDuration = 0;
							this.callStatus = false;
							this.formIntention.intention = 'C';
							this.formIntention.remark = '';
							this.formIntention.isRemark = 'false';
							this.formIntention.attribute1.label = '';
							this.formIntention.attribute1.value = '';
							this.formIntention.attribute2.label = '';
							this.formIntention.attribute2.value = '';
							this.formIntention.attribute3.label = '';
							this.formIntention.attribute3.value = '';
							this.formIntention.attribute4.label = '';
							this.formIntention.attribute4.value = '';
							this.formIntention.attribute5.label = '';
							this.formIntention.attribute5.value = '';
							this.phone = ''; //为了手动拨号后input清空
							this.phoneNumber = ''; //为了手动拨号后input清空
							this.getData();
						} else if (res.data == false) {
							this.$message.error('请挂断电话后操作');
							this.getData();
						}
					}
				});
			},
			socketForced() {
				let data = {
					userId: this.getLocalStorageItem('user'),
					userPhone: this.getLocalStorageItem('ms_username'),
					phoneNumber: this.phoneNumber,
					intention: this.formIntention.intention,
					remark: this.formIntention.remark
				};
				socketforced(data).then(res => {
					if (res.code == 200) {
						this.getData();
						this.$message.success(res.message);
						this.dialogIntention = false;
						this.callStatus = false;
						this.arrI = 0;
						this.setSeconds = this.getLocalStorageItem('setSeconds'); //重新获取间隔拨打时间
						this.formIntention.intention = 'C';
						this.formIntention.remark = '';
						this.phoneNumber = '';
					} else {
						this.$message.error(res.message);
					}
				});
			},
			callPopup5Close() {
				this.callPopup5 = false;
			},
			getblack() {
				this.blackremark = '其他';
				this.callPopup5 = true;
			},
			//黑名单选项
			blackRadioChange(evt) {
				this.blackremark = evt;
			},
			getblackListAdd() {
				let data = {
					userId: this.getLocalStorageItem('user'),
					phoneNumber: this.phoneNumber,
					remark: this.blackremark
				};
				blackListAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.dialogIntention = false;
						this.callPopup5 = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getisRemark(value) {
				this.formIntention.isRemark = this.formIntention.isRemark == value ? '' : value;
				this.isRemarkKey = !this.isRemarkKey;
				this.setSeconds = 0;
			},
			formatSeconds(result) {
				var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				var m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result /
					60) % 60);
				var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
				if (h > 0) {
					return (result = h + ':' + m + ':' + s);
				} else {
					return (result = m + ':' + s);
				}
			},
			closCustomVisibleDialog() {
				this.customVisible = false;
				this.tableData = [];
				this.customData = [];
				this.getColumn();
			},
			handleChange(value, direction, movedKeys) {
				this.rightChecked = value;
				// this.rightChecked = [];
			},

			removeHyphensFromPhoneNumber(phoneNumber) {
				// 使用正则表达式匹配一个或多个连字符，并将其替换为空字符串
				return phoneNumber.replace(/-/g, '');
			},
			initFormIntention() {
				this.formIntention = {
					attribute1: {},
					attribute2: {},
					attribute3: {},
					attribute4: {},
					attribute5: {}
				};
			},

			// 从localStorage中获取数据  
			getLocalStorageItem(key) {
				return localStorage.getItem(key);
			},

			// 准备发送给socket的数据  
			prepareSocketData(row, formIntention) {
				const username = this.getLocalStorageItem('ms_username');
				const protectionLevel = this.getLocalStorageItem('protectionLevel').toString();
				return {
					name: username,
					info: this.removeHyphensFromPhoneNumber(formIntention.phoneNumber),
					type: 3,
					customerName: formIntention.name,
					remark: formIntention.remark,
					protectionLevel
				};
			},

			// 处理socket响应  
			handleSocketResponse(res) {
				console.log(res, '手上可')
				this.bodadisabled = false;
				this.phoneNumber = this.formIntention.phoneNumber;

				switch (res.data) {
					case 2:
						this.dialogIntention = true;
						// ... 其他逻辑 ...  
						this.startCallDurationTimer();
						break;
					case 1:
						this.$message.error('请APP重新进入电脑快拨页面');
						// ... 其他逻辑 ...  
						break;
					case 0:
						this.showRiskNumberMessage();
						break;
					case 3:
						this.showBlacklistedNumberMessage();
						break;
						// ... 其他情况 ...  
				}
			},

			// 显示风险号码消息  
			showRiskNumberMessage() {
				let text = '此号码为“风险号码”，禁止拨打！';
				let msg = this.phoneNumber + text;
				this.$message.error(msg);
				this.arrI = this.arrI + 1;
				this.getuploedriskNumber();
			},

			// 显示黑名单号码消息  
			showBlacklistedNumberMessage() {
				let text = '号码已被拉入黑名单,不可拨打';
				let msg = this.phoneNumber + text;
				this.$message.error(msg);
				// ... 其他逻辑 ...  
			},

			// 开始调用时长计时器  
			startCallDurationTimer() {
				this.timer = setInterval(() => {
					this.callDuration++;
				}, 1000);
			},
			formatter(row) {
				const zoneDate = new Date(row.lastSyncTime).toJSON();
				const date = new Date(+new Date(zoneDate) + 8 * 3600)
					.toISOString()
					.replace(/T/g, ' ')
					.replace(/\.[\d]{3}Z/, '');
				return date;
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			handleAddress(e) {
				for (let i = 0; i < this.addressSelections.length; i++) {
					if (i == 0) {
						this.province = CodeToText[this.addressSelections[i]];
						this.addressStr = this.province;
					}
					if (i == 1) {
						this.city = CodeToText[this.addressSelections[i]];
						this.addressStr = this.addressStr + '-' + this.city;
					}
					if (i == 2) {
						this.town = CodeToText[this.addressSelections[i]];
						this.addressStr = this.addressStr + '-' + this.town;
					}
				}
			},
			handleChangedetail(e) {
				this.detail = e;
				this.addressStr = this.addressStr + '-' + e;
			},

			addData() {
				this.form.normalName = '';
				this.form.phoneNumber = '';
				this.form.gender = '';
				this.form.customerCompany = '';
				this.form.mailbox = '';
				this.form.customerIdentity = '';
				this.form.remark = '';
				this.form.customerName = '';
				this.addressSelections = '';
				this.detail = '';
				this.getwebListCustomize();
				getCustomerSchedule(this.query.companyId).then(res => {
					if (res.code == 200) {
						this.addVisible = true;
						this.customerScheduleList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//下载磨板
			getdownloadIntentionTemplate() {
				if (this.query.companyId) {
					let data = {
						companyId: this.query.companyId,
					};
					downloadIntentionTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '我的客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					let data = {
						companyId: localStorage.getItem('company')
					}
					downloadIntentionTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '我的客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				}


			},
			//获取表头设置
			getColumnSetting() {
				this.customVisible = true;
				this.customTableData = [];
				getColumnSetting(localStorage.getItem('user')).then(res => {
					if (res.code == 200) {
						this.customTableData = res.data;
						this.rowDrop();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			rowDrop() {
				let tbody = document.querySelector('#aaa-box .el-table__body-wrapper tbody');
				let _this = this;
				_this.so = Sortable.create(tbody, {
					group: {
						name: 'str',
						pull: true,
						put: true
					},
					animation: 150, // 动画参数
					sort: true,
					filter: '.not-sort', // 过滤.not-sort的元素
					onEnd(evt) {
						console.log(evt, 'sdddd')
						let data = {
							userId: localStorage.getItem('user'),
							value: evt.item.cells[1].innerText,
							index: evt.oldIndex + 1,
							nextIndex: evt.newIndex + 1,
						};
						updateColumnSetting(data).then(res => {
							if (res.code == 200) {
								_this.so.destroy();
								setTimeout(function() {
									_this.getColumnSetting();
									_this.$message.success(res.message);
								}, 500);
							} else {
								_this.$message.error(res.message);
							}
						});
					}
				});
			},
			getcustomerEnable(row) {
				let data = {
					userId: localStorage.getItem('user'),
					value: row.value,
					index: row.index,
					nextIndex: row.index,
					enable: row.enable
				};
				updateColumnSetting(data).then(res => {
					if (res.code == 200) {
						this.getColumnSetting();
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
						this.getColumnSetting();
					}
				});
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
					if (this.subAgent == true) {
						this.isSubAgent = true;
						this.getquerySubAgent();
					}
				});
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			getfetchDefaultCompanies() {
				if (this.role == 'subAgent') {
					this.query.companyId = '';
				}
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getwebListCustomize() {
				let data = {
					companyId: this.query.companyId
				};
				getwebListCustomize(data).then(res => {
					if (res.code == 200) {
						this.customList = res.data;
						this.customList.forEach((i, index) => {
							this.customize[index] = '';
							i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
							i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
							if (i.options) {
								this.customList[index].options = Array.from(i.options, ({
									str
								}) => str);
							}
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCustomerSchedule() {
				getCustomerSchedule(this.query.companyId).then(res => {
					if (res.code == 200) {
						this.customerScheduleList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCustomerSource() {
				if (this.query.companyId) {
					getCustomerSource(this.query.companyId).then(res => {
						if (res.code == 200) {
							this.ustomerSourceList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					getCustomerSource(this.agents[0].value).then(res => {
						if (res.code == 200) {
							this.ustomerSourceList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},

			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;

				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getColumn();
					// this.getData();
				});
			},
			getColumn() {
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.query.companyId
				}
				getColumn(data).then(res => {
					res.data.forEach(i => {
						i.width = 180
					})
					this.customData = res.data;
					if (this.query.companyId) {
						this.getData();
					}
				});
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
					this.query.normalName = '';
					this.form.normalName = '';
					// this.CRMform.normalName = '';
				});
			},

			//点击当前行
			showData(row) {
				this.activeName = 'contactInformation';
				this.getfindByRecordId(row.id);
			},
			radioChange(e) {},
			//用户详情
			getfindByRecordId(id) {
				intentionCustomergetDetails(id).then(res => {
					if (this.desensitization == true) {
						const start = Math.max(0, Math.floor((res.data.phoneNumber.length - 4) / 2));
						const end = Math.min(res.data.phoneNumber.length, start + 4);
						res.data.phoneNumber = res.data.phoneNumber.slice(0, start) + '****' + res.data.phoneNumber
							.slice(end);
					}
					// this.activeName = 'contactInformation';
					this.customerDetails = res.data;
					this.getfindCallRecord();

					this.addressSelections = [];
					this.detail = '';
					this.operationLoglenght = res.data.operationLog.length;
					this.journallenght = res.data.journal.length;
					// this.form.userId= this.customerDetails.normalName;
					this.form = res.data;
					// this.users.forEach(i => {
					// 	if (i.label == this.form.normalName) {
					// 		this.form.normalName = i.value;
					// 	} else {
					// 		console.log(this.form.normalName, 'this.form.normalName');
					// 	}
					// });
					this.form.customizeVo.forEach((i, index) => {
						this.customize[index] = '';
						i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
						// i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
						if (i.type == 'multi-select-drop-down-box') {
							if (i.integerList.length) {
								i.options.forEach((item, indexs) => {
									i.integerList.forEach(name => {
										if (item.index2 == name) {
											i.multiSelectDropDownBox.push(item.str);
											item.checked = true;
										}
									});
								});
							}
						} else if (i.type == 'region') {
							if (i.textVal) {
								let item = i.textVal.split('-');
								for (let i = 0; i < item.length; i++) {
									if (TextToCode[item[0]]) {
										if (i == 0) {
											this.province = item[i];
											this.addressSelections.push(TextToCode[item[0]].code);
										}
									}
									if (TextToCode[item[0]][item[1]]) {
										if (i == 1) {
											this.province = item[i];
											this.addressSelections.push(TextToCode[item[0]][item[1]].code);
										}
									}
									if (TextToCode[item[0]][item[1]][item[2]]) {
										if (i == 2) {
											this.town = item[i];
											this.addressSelections.push(TextToCode[item[0]][item[1]][item[
												2]].code);
										}
									}
									if (i == 3) {
										this.detail = item[i];
									}
								}
							}
						}
					});

					this.drawerInputDis = true;
					this.drawer = true;
				});
			},
			handleClick(e) {
				this.drawerInputDis = true;
				this.callRecordBox = false;
				if (e.props.label == '通话记录' || e.props.label == '联系人信息') {
					this.getfindCallRecord();
				}
			},
			getfindCallRecord() {
				getfindCallRecord(this.customerDetails.id).then(res => {
					if (res.code == 200) {
						this.followUpRecordData = res.data;
					}
				});
			},
			handleChangeAudioVolume() {
				if (this.$refs.audio) this.$refs.audio.audioHuds = false;
			},
			toObject(arr) {
				this.rv = {};
				for (let i = 0; i < arr.length; ++i) this.rv[i] = arr[i];
				return this.rv;
			},
			updateIntentionCustomer() {
				this.form.customize = [];
				this.form.customizeVo.forEach((item, index) => {
					console.log(item, 'sslsll')
					if (item.type == 'multi-select-drop-down-box') {
						if (item.multiSelectDropDownBox) {
							this.form.customize[index] = item.multiSelectDropDownBox.toString();
						}
					} else if (item.type == 'region') {
						this.form.customize[index] = this.addressStr + '-' + this.detail;
					} else if (item.type == 'date') {
						this.form.customize[index] = this.timestampToDateString(item.textVal);
					} else {
						this.form.customize[index] = item.textVal;
					}
				});
				this.form.userId = localStorage.getItem('user');
				this.form.customize = this.toObject(this.form.customize); //变成对象
				this.form.web = true;
				if (this.role != 'normal') {
					if (this.form.normalName == '') {
						this.$message.error('请选择跟进人员');
						return;
					} else {
						console.log(this.users, '我是编辑users')
						console.log(this.form, '我是编辑的参数')
						if (this.users !== null && this.users.length > 0) {
							this.users.forEach(i => {
								if (i.label == this.form.normalName) {
									this.form.normalName = i.value;
								}
							});
						} else {
							this.$message.error('请选择跟进人员');
							return;
						}

					}

				} else {
					this.form.normalName = localStorage.getItem('user');
				}
				this.form.customizeVo = null;
				this.form.companyId = this.query.companyId;
				updateIntentionCustomer(JSON.stringify(this.form)).then(res => {
					if (res.code == 200) {
						this.getupdateSchedule();
					}
				});
			},
			getAddIntentionCustomer() {
				this.customList.forEach((item, index) => {
					if (item.type == 'multi-select-drop-down-box') {
						if (item.multiSelectDropDownBox) {
							this.form.customize[index] = item.multiSelectDropDownBox.toString();
						}
					} else {
						this.form.customize[index] = item.textVal;
					}
				});

				this.form.userId = localStorage.getItem('user');
				this.form.customize = this.toObject(this.form.customize); //变成对象
				this.form.web = true;
				if (this.role == 'normal') {
					this.form.normalName = this.users[0].value;
				} else {
					if (this.form.normalName == '') {
						this.$message.error('请选择跟进人员');
						return;
					}
				}
				addIntentionCustomer(JSON.stringify(this.form)).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.customList = [];
						this.expansion = [];
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.userDis = false;
				this.$refs[formName].resetFields();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 获取数据
			uploadExcel() {

				this.upload = true;
			},
			// 导入弹出
			uploadExcel() {
				this.upload = true;
			},
			closeUpload() {
				this.upload = false;
				this.$refs.upload.clearFiles();
			},

			handleRemove(file, fileList) {
				console.log(file, fileList, 'handleRemove');
			},
			handlePreview(file) {
				console.log(file, 'handlePreview');
			},
			uploadSuccess(success) {
				if (success.code == 200) {
					this.$message.success('上传成功');
				} else {
					this.$message.error(success.message);
				}
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
				this.getData();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.uploadform.userIds = this.uploadform.userIds.toString()
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			//分配的员工id字符串化
			handleCheckedCitiesChange(value) {
				console.log(value, 'valuevalue')
				this.uploadform.userIds = value;
			},

			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			getTime() {
				this.timeArr.push(this.query.startTime);
				this.timeArr.push(this.query.endTime);
				this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
				this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			},
			getData() {
				this.loading = true;
				this.tableData = [];
				this.expansion = [];
				this.query.userId = localStorage.getItem('user');
				this.query.company = localStorage.getItem('company');
				this.customList.forEach(i => {
					let str = ''; // 在顶层初始化 str  
					let obj = {}; // 在顶层初始化 obj  

					if (i.type == 'multi-select-drop-down-box') {
						i.multiSelectDropDownBox.forEach(j => {
							str += j + ',';
						});
						obj.label = i.label;
						obj.value = str.slice(0, -1); // 移除最后一个逗号（如果有）  
					} else if (i.type == "radio-drop-down-box") {
						// 假设有一个 multiSelectDropDownBox 属性  
						str = i.multiSelectDropDownBox || '';
						obj.label = i.label;
						obj.value = str;

					} else if (i.type == "date") {
						if (i.textVal === null || !i.textVal) {
							obj.label = i.label;
							obj.value = '';
						} else {
							obj.label = i.label;
							obj.value = this.timestampToDateString(i.textVal);
						}
					} else if (i.textVal) {
						obj.label = i.label;
						obj.value = i.textVal;
					}

					// 在每种类型处理完后，一次性将 obj 添加到 this.expansion  
					if (Object.keys(obj).length > 0) { // 确保 obj 不是空的  
						this.expansion.push(obj);
					}

					// 重置 str 和 obj 以供下一次迭代使用  
					str = '';
					obj = {};
				});
				this.query.expansion = JSON.stringify(this.expansion);
				intentionCustomerList(this.query).then(res => {
					this.loading = false;
					this.tableData = [];
					if (res.code == 200) {
						if (res.customData == true) {
							this.desensitization = true;
							res.data.list.forEach(i => {
								const start = Math.max(0, Math.floor((i.phoneNumber.length - 4) / 2));
								const end = Math.min(i.phoneNumber.length, start + 4);
								i.phoneNumbers = i.phoneNumber;
								i.phoneNumber = i.phoneNumber.slice(0, start) + '****' + i.phoneNumber
									.slice(end);
								this.tableData.push(i);
							})
						} else {
							this.desensitization = true;
							res.data.list.forEach(i => {
								const start = Math.max(0, Math.floor((i.phoneNumber.length - 4) / 2));
								const end = Math.min(i.phoneNumber.length, start + 4);
								i.phoneNumbers = i.phoneNumber;
								i.phoneNumber = i.phoneNumber.slice(0, start) + '****' + i.phoneNumber
									.slice(end);
								this.tableData.push(i);
							})
						}
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			timestampToDateString(timestamp) {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			handleDownload() {
				this.expansion = [];
				this.customList.forEach(i => {
					let str = ''; // 在顶层初始化 str  
					let obj = {}; // 在顶层初始化 obj  

					if (i.type == 'multi-select-drop-down-box') {
						i.multiSelectDropDownBox.forEach(j => {
							str += j + ',';
						});
						obj.label = i.label;
						obj.value = str.slice(0, -1); // 移除最后一个逗号（如果有）  
					} else if (i.type == "radio-drop-down-box") {
						// 假设有一个 multiSelectDropDownBox 属性  
						str = i.multiSelectDropDownBox || '';
						obj.label = i.label;
						obj.value = str;

					} else if (i.type == "date") {
						if (i.textVal === null || !i.textVal) {
							obj.label = i.label;
							obj.value = '';
						} else {
							obj.label = i.label;
							obj.value = this.timestampToDateString(i.textVal);
						}
					} else if (i.textVal) {
						obj.label = i.label;
						obj.value = i.textVal;
					}

					// 在每种类型处理完后，一次性将 obj 添加到 this.expansion  
					if (Object.keys(obj).length > 0) { // 确保 obj 不是空的  
						this.expansion.push(obj);
					}

					// 重置 str 和 obj 以供下一次迭代使用  
					str = '';
					obj = {};
				});
				this.query.expansion = JSON.stringify(this.expansion);
				this.query.type = 1;
				downloadIntentionCustomerList(this.query).then(res => {
					console.log(res, '撒拉卡了')
					if (res.type == 'application/json') {
						return this.$message.error(res.message)
					}
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = 'CRM报表' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);

					const reader = new FileReader();
					reader.onload = function(event) {
						console.log(event.target.result, '是顶顶顶顶'); // 输出文件内容，但可能不是可读的文本  
					};
					reader.readAsText(blob); // 尝试将 Blob 作为文本读取
				});
			},
			getSchedule(value) {
				this.query.schedule = this.query.schedule == value ? '' : value;
				this.scheduleKey = !this.scheduleKey;
			},
			getSource(value) {
				this.query.source = this.query.source == value ? '' : value;
				this.sourceKey = !this.sourceKey;
			},
			getSource2(value) {
				this.form.source = this.form.source == value ? '' : value;
			},
			getNotUpdated(value) {
				this.query.notUpdated = this.query.notUpdated == value ? '' : value;
				this.notUpdatedKey = !this.notUpdatedKey;
			},
			getType(value) {
				this.query.type = this.query.type == value ? '' : value;
				this.typeKey = !this.typeKey;
			},
			getGender(value) {
				this.query.gender = this.query.gender == value ? '' : value;
				this.genderKey = !this.genderKey;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			handleEdit() {
				this.activeName = 'contactInformation';
				this.drawerInputDis = false;
			},

			getNoupdateIntentionCustomer() {
				this.drawerInputDis = true;
			},

			getCustomerReleases() {
				if (this.form.companyId) {
					getCustomerRelease(this.form.companyId).then(res => {
						if (res.code == 200) {
							this.shifangList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					getCustomerRelease(localStorage.getItem('company')).then(res => {
						if (res.code == 200) {
							this.shifangList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			shifangRadioChange(e) {
				this.shifangbox.shifangVal = e;
			},
			deleteIntentionCustomers() {
				let data = {
					id: this.shifangId,
					cause: this.shifangbox.shifangVal,
					userId: localStorage.getItem('user')
				};
				deleteIntentionCustomer(data).then(res => {
					if (res.code == 200) {
						this.$message.success('释放成功');
						this.shifangId = '';
						this.shifangbox.shifangVal = '';
						this.shifang = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeshifangDialog() {
				this.shifang = false;
				this.shifangbox.shifangVal = '';
				this.shifangId = '';
			},
			// 删除操作
			handleDelete() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.shifang = true;
					this.getCustomerReleases();
					this.shifangId = str;
				}
			},
			handleDelete2(id) {
				this.shifang = true;
				this.getCustomerReleases();
				this.shifangId = id;
			},
			getRemoveCrm2() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							let param = {
								ids: str,
								userId: localStorage.getItem('user')
							};
							removeCrm(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getData();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}

				this.multipleSelection = [];
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},

			getupdateSchedule() {
				let data = {
					id: this.customerDetails.id,
					schedule: this.form.scheduleIndex
				};
				updateSchedule(data).then(res => {
					if (res.code == 200) {
						this.drawerInputDis = true;
						this.getData();
						this.getfindByRecordId(this.customerDetails.id);
						this.$message.success(res.message);
					} else {
						this.drawerInputDis = false;
						this.$message.error(res.message);
					}
				});
			},
			tapNav(e) {
				this.tapnav = e;
				this.form.scheduleIndex = e;
				this.getupdateSchedule();
			},
			showcallRecordBox() {
				this.callRecordBox = true;
			},
			hidecallRecordBox() {
				this.callRecordBox = false;
			},
			GetIntentionCustomeraddJournals() {
				let data = {
					id: this.customerDetails.id,
					journal: this.journal
				};
				GetIntentionCustomeraddJournal(data).then(res => {
					if (res.code == 200) {
						this.callRecordBox = false;
						this.journal = '';
						this.getfindByRecordId(this.customerDetails.id);
					} else {
						this.getfindByRecordId(this.customerDetails.id);
						this.$message.error(res.message);
					}
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.multipleSelectionlen = val.length;
			},
			turnaroundCustomer() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.transferVisible = true;
				}
			},
			transferPhoneNumberPool() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					let data = {
						userId: localStorage.getItem('user'),
						ids: str,
						normalId: this.form.userId
					};
					turnaroundCustomer(data).then(res => {
						if (res.code == 200) {
							this.getData();
							this.transferVisible = false;
							this.$message.success(res.data);
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},



		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.drawer-box {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.drawer-box .header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.drawer-box .header .user-box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.drawer-box .header .user-box .user-name {
		display: flex;
		flex-direction: row;
	}

	.drawer-box .header .user-box .user-name img {
		width: 50px;
		height: 50px;
		margin-right: 20px;
	}

	.drawer-box .header .user-box .user-name .name div {
		color: #000;
		font-weight: bold;
	}

	.drawer-box .header .user-box .user-name .name .el-input.is-disabled .el-input__inner {
		color: #746f69;
		font-weight: bold;
	}

	.drawer-box .header .user-info {
		display: flex;
		flex-direction: row;
		margin: 10px 0;
	}

	.drawer-box .header .user-info .list {
		margin-right: 100px;
	}

	.drawer-box .header .user-info .list .name-label {
		color: #000;
		font-weight: 600;
	}

	.drawer-box .header .user-info .list .name-data {
		color: #000;
	}

	.drawer-box .nav-tab {
		border-top: 10px solid #f1f2f6;
		border-bottom: 10px solid #f1f2f6;
	}

	.drawer-box .nav-tab .ul-box {
		display: flex;
		flex-direction: row;

		width: 70%;
		margin: 20px 0;
		border-radius: 20px;
	}

	.drawer-box .nav-tab .ul-box div {
		height: 40px;
		text-align: center;
		line-height: 40px;
		width: 25%;
		margin-right: -10px;
		background-color: #f1f2f6;
		cursor: pointer;
	}

	.drawer-box .nav-tab .ul-box div:first-child {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		/* clip-path: circle(100% at 30% 10%); */
	}

	.drawer-box .nav-tab .ul-box div:last-child {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		/* clip-path: circle(40% 0%, 40% 20%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 20%,100% at 30% 10%); */
	}

	.drawer-box .nav-tab .ul-box .active {
		border: 2px solid #28b87d;
		background-color: #fff;
		color: #28b87d;
		height: 36px;
		line-height: 36px;
		margin-right: 1px;
	}

	.drawer-box .drawer-box .drawer-content .title {
		margin-bottom: 6px;
		font-weight: 600;
	}

	.drawer-box .add-journal {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: #3f8afd;
		color: #fff;
		font-size: 30px;
		position: absolute;
		bottom: 30px;
		left: 30px;
		text-align: center;
		cursor: pointer;
	}

	.drawer-box .drawer-content .title span {
		width: 4px;
		height: 14px;
		position: relative;
		top: 2px;
		display: inline-block;
		background-color: #408bfe;
	}

	.drawer-box .drawer-content .call-record-box {
		display: flex;
		flex-direction: row;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info {
		width: 50%;
		float: left;
		margin-bottom: 6px;
		line-height: 40px;
		min-height: 45px;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info .el-input.is-disabled .el-input__inner {
		color: #373635;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info .lat {
		display: inline-block;
		width: 100px;
		font-weight: 500;
		color: #000;
		line-height: 40px;
		float: left;
	}

	/* radio单选 变成正方形 */
	::v-deep .el-radio__inner {
		border-radius: 2px;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid #c0c4cc;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 2px;
		left: 0px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}

	::v-deep .el-icon-time,
	::v-deep .el-icon-date {
		display: none;
	}

	.icon-style {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 12px;
		color: #666;
		cursor: pointer;
		margin: 20px 10px;
		height: 40px;
		line-height: 40px;
	}

	.icon-style:hover {
		background-color: #e5e9f0;
	}

	.icon-style i {
		font-size: 16px;
		position: relative;
		height: 40px;
		line-height: 40px;
		left: -6px;
	}

	.query-list .el-form-item {
		padding: 6px 20px !important;
		margin-bottom: 0 !important;
	}

	.query-list .el-form-item:hover {
		background-color: #e5e9f0;
		cursor: pointer;
	}

	.loyo-folding-btn:hover {
		border-color: #2c9dfc;
	}

	.loyo-folding-btn {
		position: relative;
		color: #2c9dfc;
		height: 20px;
		text-align: center;
		border-top: 1px solid #e5e9f0;
		margin-top: 15px;
		cursor: pointer;
	}

	.loyo-folding-btn a {
		position: absolute;
		bottom: -1px;
		border-bottom: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-top: 3px solid #ddd;
		border-top-color: #fff;
		width: 60px;
		height: 20px;
		line-height: 20px;
	}

	.loyo-folding-btn a i {
		font-size: 18px;
		color: #ddd;
	}

	.loyo-folding-btn:hover i {
		color: #2c9dfc;
	}

	.loyo-folding-btn:hover a {
		/* border: 1px solid #2c9dfc; */
		border-bottom: 1px solid #2c9dfc;
		border-left: 1px solid #2c9dfc;
		border-right: 1px solid #2c9dfc;
		border-top: 3px solid #2c9dfc;
		border-top-color: #fff;
	}

	.custom-data {
		display: inline-block;
		margin-top: 10px;
		margin-right: 10px;

	}

	.custom-data .label-name {
		width: 90px;
		line-height: 35px;
		font-weight: 500;
		margin-right: 10px;

	}

	.custom-box {
		display: flex;
		flex-direction: row;
		line-height: 35px;
	}

	::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
		cursor: move !important;
	}

	.form-items-wrapper {
		display: flex;
		flex-wrap: wrap;
		/* 允许子元素换行 */
		justify-content: flex-start;
		/* 水平对齐方式（可选） */
		align-items: center;

	}

	.form-items-wrapper .el-form-item {
		/* 根据需要调整 el-form-item 的样式 */
		flex: 0 0 auto;
		/* 不放大也不缩小，但会占据空间 */
		margin: 5px 10px 5px 0;
		display: flex;
		flex-direction: row;
		/* 如果使用 gap 属性，则不需要这个 */
	}

	.form-items-wrapper .el-form-item__label {
		/* 调整标签样式（可选） */
	}

	.data-pool-operation {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #f3f3f3;
		padding: 10px 20px;
	}

	.loyo-folding-btn {
		position: relative;
		text-align: center;
		cursor: pointer;
		left: -51%;
		top: -60px;
		border-color: #2c9dfc;
	}

	.loyo-folding-btn a {
		width: 60px;
		height: 20px;
		line-height: 20px;
	}

	.loyo-folding-btn a i {
		font-size: 18px;
		color: #2c9dfc;
	}

	.call-type5 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.call-type5 .input-box {
		height: 60px;
		border-bottom: 1px solid #ccc;
	}

	.call-type5 .black-radio {
		position: relative;
		top: -15px;
		border: 1px solid #4f7afd;
		padding: 10px;
	}

	.call-type5 .black-radio .title {
		font-size: 16px;
		color: #000;
	}

	.call-type5 .black-radio .el-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.call-type5 .black-radio .el-radio-group .el-radio {
		margin: 6px 2px;
	}

	.call-type5 .btn-box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 30px;
	}

	.call-type5 .btn-box button {
		width: 84px;
		height: 34px;
		margin: 0;
		padding: 0;
		line-height: 34px;
		text-align: center;
		border-radius: 8px;
		color: #fff;
		font-size: 16px;
		background-color: #007bfe;
		border: none;
	}
</style>
<style type="text/css">
	.el-checkbox__input.is-disabled+span.el-checkbox__label,
	.el-radio__input.is-disabled+span.el-radio__label,
	.el-textarea.is-disabled .el-textarea__inner {
		color: #746f69 !important
	}

	.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after,
	.el-radio__input.is-checked .el-radio__inner::after {
		border-color: #746f69 !important
	}

	.el-drawer {
		/* height: calc(100% - 165px) !important; */
		padding: 16px !important;
		font-size: 12px !important;
		line-height: 1.5 !important;
		word-wrap: break-word !important;
		position: absolute !important;
		/* top: 165px !important; */
		overflow: auto !important;
	}

	.input-with-select .el-input {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.drawer-box .bordis {
		border: none;
		background-color: #fff;
	}

	.drawer-box .el-input.is-disabled .el-input__inner {
		border: none;
		padding: 0;
		color: #746f69;
		background-color: #fff;
	}

	.el-dialog .el-dialog__body .dialog-title {
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		text-align: center !important;
		position: relative !important;
		top: -28px !important;
	}

	.el-dialog .el-dialog__body .dialog-title i {
		font-size: 50px !important;
		color: #007bfe !important;
	}

	.el-dialog .el-dialog__body .dialog-title .title {
		font-size: 22px;
		font-weight: 500;
		margin: 6px 0;
	}

	.el-dialog .el-dialog__body .dialog-title .title img {
		width: 30px;
		height: 30px;
		position: relative;
		top: 6px;
	}

	.el-dialog .el-dialog__body .dialog-title .time {
		font-weight: 500;
		font-size: 18px;
		color: rgb(16, 142, 233);
	}

	.input-box .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
		font-size: 24px !important;
		color: #333 !important;
	}

	/deep/ .el-dialog {
		margin-top: 2vh !important;
	}
</style>