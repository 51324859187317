<template>
	<div>
		<div class="container">
			<div class="handle-box" style="margin-bottom: 20px;">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
					placeholder="代理商" style="width: 260px;margin-right: 10px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>

				<el-select size="small" style="width: 260px;margin-right: 10px;" v-model="query.companyId" filterable
					clearable placeholder="企业">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="getData">搜索</el-button>

			</div>
			<el-table :data="tableData" :border='true' class="table" ref="multipleTable" v-loading="loading"
				header-cell-class-name="table-header" @row-click="showData"
				:cell-style="{ cursor: 'pointer', padding: '6px' }" :row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange" style="width: 100%;">
				<el-table-column prop="userPhone" label="操作者" align="center" width="180"></el-table-column>
				<el-table-column prop="companyName" width="340" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="content" label="内容" align="center"></el-table-column>
				<el-table-column prop="time" label="时间" align="center" width="180"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies, companyLvLog } from '../api/companyIndex';
	export default {
		name: 'businessRecords',
		data() {
			return {
				query: {
					agentId: '',
					companyId: '',
					pageIndex: 1,
					pageSize: 30,
				},
				tableData: [],
				companys: [],
				agents: [],
				pageTotal: 0,
				theme: localStorage.getItem('theme'),
			};
		},
		created() {
			this.getfetchAgent();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getfetchAgent() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;

				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.getData();
			},
			getData() {
				this.query.userId = localStorage.getItem('user');
				companyLvLog(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},

		}
	};
</script>